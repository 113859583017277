@font-face {
    font-family: 'PublicSansBold';
    src: url('../fonts/PublicaSansRound-Bd.otf') format('opentype'),
}

@font-face {
    font-family: 'PublicSansLight';
    src: url('../fonts/PublicaSansRound-Lt.otf') format('opentype');
}


@font-face {
    font-family: 'PublicSansRegular';
    src: url('../fonts/PublicaSansRound-Rg.otf') format('opentype');
}


@font-face {
    font-family: 'FuturaNow';
    src: url('../fonts/FuturaNowHeadlineBold.ttf') format('truetype');
}


.main {
    display: flex;
    flex: 1;
    min-height: 100vh;
}

.leftSideContainer {
    width: 100%;
    padding-top: 10%;
    padding-bottom: 30%;
    margin-left: auto;
    margin-right: auto;
}

.tictoc,
.tictoc::before {
    content: "tic... toc... tic...";
    font-family: 'PublicSansLight';
    color: white;
    font-size: 3vw;
    width:30vw;
}

.landing {
    font-family: 'FuturaNow';
    color: white;
    font-size: 6vw;
}

.rightSideContainer {
    width: 100%;
    padding-bottom: 30%;
    margin-left: auto;
    margin-right: auto;
}

.brand-image {
    height: 5vh;
    width: 15vw;
    margin: 20%;
    position: relative;
    max-height: 100px;
    min-width: 200px;
}

.bg-image-tictoctickets {
    background-image: url(../img/Background.jpg);
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 100vw;
    min-height: 100vh;

}

.poster-container {
    min-width: 50vw;
}

.poster-image {
    width: 50%;
    height: 50vh;
    padding: 2%;
    object-fit: contain;
}

.poster-image1 {
    width: 50%;
    height: 50vh;
    padding: 2%;
    object-fit: contain;

}


.upnext {
    position: relative;
    font-family: 'FuturaNow';
    color: #F13228;
    font-size: 3vw;
}

.footer {
    font-family: 'PublicaSansRegular';
    color: #FFFDF2;
    padding-left: 3vw;
}


@media only screen and (max-width: 649px) {
    .main {
        flex-direction: column;
    }

    .brand-image {
        width: 45vw;
    }

    .navbar {
        margin-left: auto;
        margin-right: auto;
    }

    .leftSideContainer {
        width: 100%;
    }

    .rightSideContainer {
        width: 100%;
    }

    .tictoc {
        font-size: 150%;
        text-align: center;
        width: 87vw;
    }

    .landing {
        font-size: 250%;
        text-align: center;
    }

    .upnext {
        font-size: 5vw;
        padding-top: 0vh;
        text-align: center;
    }

    .poster-image {
        height: 60vh;
        width: 90vw;
        padding-top: 5vh;
    }

    .poster-image1 {
        height: 60vh;
        width: 90vw;
        padding-top: 5vh;
    }

    .poster-container {
        flex-direction: column;
    }

    .footer {
        text-align: center;
    }
}


@media (prefers-reduced-motion: no-preference) {
    .toc::before {
        content: " toc...";
        animation: text-blink infinite 1.5s;
    }
}


@keyframes text-blink {

    0%,
    100% {
        content: " toc...";
        color: rgba(0, 0, 0, 0);
    }

    50% {
        content: " toc...";
        color: #FFC915;
    }
}